$(function () {
    Livewire.on('closeModal', (selector) => {
        closeModal(selector.replace('#', ''))
    });

    Livewire.on('openModal', (selector = ".modal") => {
        openModal(selector.replace('#', ''))
    });

    Livewire.on('openAlertModal', data => {
        openAlertModal(data)
    });

    Livewire.on('openOauthClientDataModal', data => {
        openModal(data)
    });

    window.openAlertModal = function(data)
    {
        var selector = $('#modal-alert');
        selector.find('.modal-body').html(data.content);
        openModal('modal-alert');
    }

    window.openModal = function(id){
        new bootstrap.Modal(document.getElementById(id), {
            keyboard: false
        }).show();
    }

    window.closeModal = function(id){
        var modal = bootstrap.Modal.getInstance(document.getElementById(id)).hide();
    }
});
