window.$ = require('jquery');
require('select2/dist/js/select2');
require('../custom/livewire-modal')
document.addEventListener('livewire:load', function () {
    $(document).ready(function(){
        initSelect2();
    });

    Livewire.hook('message.processed', (message, component) => {
        initSelect2();
    });
})


window.initSelect2 = function (selector = '.select2') {
    $('.select2').each(function() {
        $(this).select2({
            theme: "bootstrap-5",
            dropdownParent: $(this).parent(),
        });
        $(document).on('change', '.select2', function(e){
            var model = $(this).attr('wire:model');
            var id = $(this).closest('div[wire\\:id]').attr('wire:id');
            window.livewire.find(id).set(model, e.target.value)
        });
    })
};

window.isMobile = function () {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}
